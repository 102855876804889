import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/footer';
import InfoBg from '../../Img/InfoBG.png';
import Logoex from '../../Img/logoex.png';
import Logo1 from '../../Img/logoex1.png';
import Logo2 from '../../Img/logoex2.png';
import Logo3 from '../../Img/logoex3.png';
import Logo4 from '../../Img/logoex4.png';
import Logo6 from '../../Img/logoex6.png';
import Logo7 from '../../Img/logoex7.png';
import Sign from '../../Img/sign.png';
import IMG1 from '../../Img/2022.png';
import IMG2 from '../../Img/2023.png';
import IMG3 from '../../Img/2024.png';
import IMG4 from '../../Img/2025.png';
import '../InfoPage/info.css';


import { FaAngleRight,FaPhone,FaRegClock } from "react-icons/fa6";

const InfoPage = () => {
    // const navigate = useNavigate();
    const [isImageLoaded, setImageLoaded] = useState(false);
  

    return (
        <>
        <div>
            <div className='w-full lg:pt-0 pt-[75px] '>
                <img src={InfoBg}
                    className='lg:w-full'  
                    onLoad={() => setImageLoaded(true)}
                    style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }} />
            </div>
            {isImageLoaded && (
            <>
            <div id='info1'className=''> 
            <div className='flex justify-center lg:mt-[160px] mt-[41px] '>
                    <div className='lg:w-[70%] w-[80%] '>
                        <div className='lg:text-[98px] text-[26px]  font-black tracking-widest  lg:h-[120px]'>회사</div>
                        <div className='border border-[#d9d9d9]'> </div>
                        <div className='lg:text-[98px] text-[26px]  font-black tracking-widest '>소개</div>
                    </div>
                </div>   
                <div className='flex justify-center mt-[84px]'>
                    <img src={Logoex} className='lg:w-auto w-[150px] lg:h-auto h-[56px]'/>  
                    <div className='lg:pl-[32px] pl-[12px]'>
                        <h1 className='lg:text-[45px] text-[13px] font-medium'>(주)정션메드</h1>
                        <p className='lg:text-[30px] text-[8px] lg:pt-[28px] pt-2'> 가족과 연결해주는 통로의 의미의 (Junction)과 
                        </p>
                        <p className='lg:text-[30px] text-[8px]'> 
                            건강,의료의 의미의 Med를 결합해 만든 사 명
                        </p>
                    </div>
                </div>
                <div className='lg:mt-[160px] mt-[41px]'>
                    <div className='flex justify-center w-full'>
                        <div className='flex items-center'>
                            <img src={Logo1} className='lg:h-auto h-[13px] ' />
                            <div className='lg:text-[28px] text-[8px] lg:pl-[28px] lg:pr-[22px] pr-2 pl-2'>자연,성장,생명</div>
                        </div>
                        <div className='flex items-center '>
                            <img src={Logo2} className='lg:h-auto h-[13px] ' />
                            <p className='lg:text-[28px] text-[8px] lg:pl-[28px] lg:pr-[22px] pr-2 pl-2'>평온과 안정</p>
                        </div>
                        <div className='flex items-center '>
                            <img src={Logo3} className='lg:h-auto h-[13px] '/>
                            <p className='lg:text-[28px] text-[8px] lg:pl-[28px] lg:pr-[22px] pr-2 pl-2'>의료</p>
                        </div>
                        <div className='flex items-center '>
                            <img src={Logo4}className='lg:h-auto h-[13px] ' />
                            <p className='lg:text-[28px] text-[8px] lg:pl-[28px] lg:pr-[22px] pr-2 pl-2'>의지</p>
                        </div>
                    </div>
                    <div className='flex justify-center lg:mt-[109px] mt-[41px]'>
                        <div className='lg:pr-[100px] pr-[32px]'>
                            <p className='lg:text-[28px] text-[8px]'>심볼형</p>
                            <img src={Logo6} className='pt-[40px] lg:w-auto w-[55px]'/>
                        </div>
                        <div>
                            <p className='lg:text-[28px] text-[8px]'>가로형</p>
                            <img src={Logo7} className='pt-[40px] lg:w-auto w-[120px]'/>
                        </div>
                    </div>

                </div>
            </div>
            <div id='info2' className=''>
                <div className='flex justify-center lg:mt-[160px] mt-[72px] '>
                    <div className='lg:w-[70%] w-[80%] '>
                        <div className='lg:text-[98px] text-[28px] font-black tracking-widest lg:h-[120px]'>대표자</div>
                        <div className='border border-[#d9d9d9]'> </div>
                        <div className='lg:text-[98px] text-[28px] font-black tracking-widest lg:h-[100px]'>인사말</div>
                        <div className=' '>
                            <h1 className='lg:text-[54px] text-[14px] text-[#0085FF] text-right font-bold'>“몸은 떨어져 있어도 <br/>마음은 항상 함께하는,케어,봄”</h1>
                        </div>  
                    </div>
                    
                </div>
                <div className=' flex justify-center'>
                    <div className='lg:text-[32px] text-[10px] lg:w-[70%] w-[80%] text-right'>
                        <p className='lg:pt-[71px] pt-[23px]'>코로나-19로, 요양원에 계시던 할머니와의 면회가 전면 금지된 적이 있었습니다. 할머니의 간단한 건강 상태라도 알고 싶다는 생각을 시작으로 우리는 '케어,봄'을 탄생시켰습니다.</p>
                        <p className='lg:pt-[53px] pt-[14px]'>케어,봄은 디지털 헬스케어 플랫폼으로, 누구나 언제 어디서든 가족과의 연결을 유지하고 건강관리를 함께 할 수 있도록 합니다. 가족이 어디서든 우리의 마음과 함께하고 있다는 것에 안심할 수 있도록, 몸은 물론 마음까지 함께하는 유대감을 전합니다.</p>
                        <p className='lg:pt-[53px]  pt-[14px]'>우리는 어린 시절부터 배운 도덕과 효를 바탕으로, 가족과의 육체적인 거리를 떠나 정신적으로도 연결되어 있다는 것을 깨달았습니다. 이러한 가치를 바탕으로 '케어,봄'은 모든 사람들에게 가족을 돌볼 수 있는 사회를 만들기 위해 더 많은 노력을 기울이고 있습니다.</p>
                        <p className='lg:pt-[53px]  pt-[14px]'>초고령화 사회로 진입하는 우리나라에서, 우리는 더 많은 사람들이 가족과의 유대감을 느낄 수 있는 사회를 만들기 위해 노력합니다. '케어,봄'은 이러한 노력의 일부로, 마음과 건강을 함께 케어하는 서비스를 제공하고 있습니다. 함께 우리 사회를 따뜻하고 풍요롭게 만들어가는 여정에 함께해 주세요.</p>
                    </div>
                
                </div>
                <div className='flex justify-center '>
                    <div className='flex justify-end w-[70%]'>
                        <img src={Sign} className='lg:w-auto w-[72px]' />
                    </div>
                </div>
                
            </div>
            <div id='info3'>
                <div className='flex justify-center lg:mt-[160px] mt-[100px] lg:mb-[160px] mb-[100px]'>
                    <div className='lg:w-[70%] w-[80%] '>
                        <div className='lg:text-[98px] text-[26px]  font-black tracking-widest lg:h-[120px]'>회사</div>
                        <div className='border border-[#d9d9d9]'> </div>
                        <div className='lg:text-[98px] text-[26px] font-black tracking-widest lg:h-[100px]'>연혁</div>                
                    </div>
                    
                </div>
                <div className='flex justify-center'>
                    <div className='lg:w-[70%] w-[80%] flex justify-between relative'>
                        <div className='w-[0] lg:block hidden'>
                            <div className="relative w-0 lg:h-[2800px] border-none">
                            <div className="absolute left-[-2px] top-0 w-[3px] h-full line-gradient"></div>

                            </div>

                        </div>
                    
                            <div className='w-full'>
                                <div className='lg:text-[48px] text-[22px] flex justify-end'> 2025</div>
                                <div className='flex items-center '>
                                    <div className='w-[20px] h-[20px] rounded-full bg-[#0085FF] absolute left-[-10px] lg:block hidden'></div>
                                    <div className='border-b border-[#0085FF] w-full border-2'></div>
                                </div>
                                <div className='lg:text-[22px] text-[12px] lg:pl-[60px]  pt-[24px] lg:pt-[48px] text-[#000]'>
                                    <div className='flex'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] '>01</p>
                                        </div>
                                        <div className='lg:max-w-[356px] font-medium '>
                                            <p className=''>‘CES 2025’참가</p>
                                            <p className='pt-2'>해외기업 PBADAO, Tribe app과 MOU</p>
                                            <p className='pt-2'>AI바우처 공급기업 선정</p>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div className='lg:text-[48px] text-[22px]  flex justify-end'> 2024</div>
                                <div className='flex items-center '>
                                    <div className='w-[20px] h-[20px] rounded-full bg-[#0085FF] absolute left-[-10px] lg:block hidden'></div>
                                    <div className='border-b border-[#0085FF] w-full border-2'></div>
                                </div>
                                <div className='lg:text-[22px] text-[12px] lg:pl-[60px] lg:pt-[48px] pt-[24px] text-[#999999]'>
                                    <div className='flex'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] '>12</p>
                                        </div>
                                        <div className='lg:max-w-[356px] font-medium '>
                                            <p className=''>‘데이터바우처 공급기업 선정</p>
                                        
                                        </div>
                                    </div>
                                    <div className='flex mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] '>11</p>
                                        </div>
                                        <div className='lg:max-w-[400px] font-medium '>
                                            <p className=''>강원 사회적경제 공공PR 피칭페어 우수상 수상</p>
                                            <p className='pt-2'>본사 원주에서 춘천으로 이전</p>
                                            
                                        </div>
                                    </div>
                                    <div className='flex  mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] '>10</p>
                                        </div>
                                        <div className='lg:max-w-[356px] font-medium '>
                                            <p className=''>춘당노다지마을 MOU</p>
                                        
                                        </div>
                                    </div>
                                    <div className='flex  mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] '>09</p>
                                        </div>
                                        <div className='lg:max-w-[356px] font-medium '>
                                            <p className=''>광주광역시와 AI 기업 MOU</p>
                                        
                                        </div>
                                    </div>
                                    <div className='flex mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] text-[#000] '>08</p>
                                        </div>
                                        <div className='lg:max-w-[400px] font-medium '>
                                            <p className='text-[#000]'>TIPS 선정</p>
                                            <p className='pt-2'>본사 원주에서 춘천으로 이전</p>
                                            
                                        </div>
                                    </div>
                                    <div className='flex  mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] text-[#000]'>07</p>
                                        </div>
                                        <div className='lg:max-w-[356px] font-medium  text-[#000]'>
                                            <p className=''>'CES 2025 광주공동관' 참가기업 선정</p>
                                        
                                        </div>
                                    </div>
                                    <div className='flex mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px]  '>06</p>
                                        </div>
                                        <div className='lg:max-w-[500px] font-medium '>
                                            <p className=''>원주시노인종합복지관 케어봄 서비스 강의</p>
                                            <p className='pt-2'>한베문화교류센터(베트남) MOU</p>
                                            <p className='pt-2'>원주노인종합복지관 실증 돌입</p>
                                            <p className='pt-2'>한국농산어촌네트워크 X 봉명고라데이영농조합 MOU</p>
                                        </div>
                                    </div>
                                    <div className='flex mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px]  text-[#000]'>05</p>
                                        </div>
                                        <div className='lg:max-w-[500px] font-medium '>
                                            <p className='text-[#000]'>더존 투자</p>
                                            <p className='pt-2'>광산구더불어락노인복지관 케어봄 서비스 강의</p>
                                            <p className='pt-2'>강원대 MOU  </p>
                                          
                                        </div>
                                    </div>
                                    <div className='flex mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] '>04</p>
                                        </div>
                                        <div className='lg:max-w-[356px] font-medium '>
                                            <p className=''>서원대 MOU</p>
                                        
                                        </div>
                                    </div>
                                    <div className='flex mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] '>03</p>
                                        </div>
                                        <div className='lg:max-w-[356px] font-medium '>
                                            <p className=''>광주 지사 설립</p>
                                        
                                        </div>
                                    </div>
                                    <div className='flex  mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px] text-[#000]'>02</p>
                                        </div>
                                        <div className='lg:max-w-[356px] font-medium  text-[#000]'>
                                            <p className=''>광주시민체감형AI바우처 선정</p>
                                        
                                        </div>
                                    </div>
                                    <div className='flex mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px]'>01</p>
                                        </div>
                                        <div className='lg:max-w-[500px] font-medium '>
                                            <p className=''>투자기술평가등급 TI-3 획득</p>
                                            <p className='pt-2'>기업부설연구소 이전 ( 마포구 : 마스터즈 타워 )</p>
                                          
                                          
                                        </div>
                                    </div>
                                    
                                    
                                   
                                </div>
                                <div className='lg:text-[48px] text-[22px] flex justify-end mt-10'> 2023</div>
                                    <div className='flex items-center '>
                                        <div className='w-[20px] h-[20px] rounded-full bg-[#5CB1FF] absolute left-[-10px] lg:block hidden'></div>
                                        <div className='border-b border-[#5CB1FF] w-full border-2 '></div>
                                    </div>
                                    <div className='lg:text-[22px] text-[12px] lg:pl-[60px] lg:pt-[48px] pt-[24px] text-[#999999]'>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px]'>11</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className=''>한국사회적기업진흥원장상 수상</p>
                                 
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                        <div className='w-[28px] mr-[10px]'>
                                            <p className='font-bold mr-[10px]'>10</p>
                                        </div>
                                        <div className='lg:max-w-[500px] font-medium '>
                                            <p className=''>G케어 실증 예비교육 진행</p>
                                            <p className='pt-2'>그레이스케일 MOU</p>
                                          
                                          
                                        </div>
                                        
                                    </div>
                                    <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px]'>09</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className=''>시니어 헬스 케어 세미나 주최
                                                </p>
                                                <p className='pt-2'>원주노인종합복지관 MOU</p>
                                 
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px]'>08</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className=''>(주)G케어 프로젝트 MOU</p>
                                                <p className='pt-2 pl-4'>X 위드커뮨협동조합</p>
                                                <p className='pt-2 pl-4'>X 두루바른 사회적 협동 조합</p>
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px]'>07</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className=''>G_IN LEVEL 4기 프로젝트 선정
                                                </p>
                                                
                                 
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px] text-[#000]'>06</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className='text-[#000]'>AI 스타트업 창업경진대회 대상 수상</p>
                                                <p className='pt-2 '>X (주)브레디헬스케어 MOU</p>
                                                <p className='pt-2 '>G_IN LEVEL 선정</p>
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px]'>05</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className=''>신보STEP-UP 도전프로그램 선정
                                                </p>
                                                
                                 
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px]'>04</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className=''>비대면 스타트업 육성사업 선정
                                                </p>
                                                <p className='pt-2 '>창업성장기술개발사업 디딤돌 선정</p>
                                 
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px]'>03</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className=''>시니어 벚꽃케어 페스티벌(in 진해군항제)주관/주최
                                                </p>
                                              
                                 
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px] text-[#000]'>02</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className='text-[#000]'>에스큐빅 엔젤스 후속 Seed 투자
                                                </p>
                                              
                                 
                                            </div>
                                        </div>
                                        <div className='flex mt-[12px]'>
                                            <div className='w-[28px] mr-[10px]'>
                                                <p className='font-bold mr-[10px]'>01</p>
                                            </div>
                                            <div className='lg:max-w-[500px] font-medium '>
                                                <p className=''>벤처기업 인증
                                                </p>
                                              
                                 
                                            </div>
                                        </div>
                                    </div>
                                    <div className='lg:text-[48px] text-[22px]  flex justify-end mt-10 text-[#999]'> 2022</div>
                                <div className='flex items-center '>
                                    <div className='w-[20px] h-[20px] rounded-full bg-[#5CB1FF] absolute left-[-10px] lg:block hidden'></div>
                                    <div className='border-b border-[#5CB1FF] w-full border-2'></div>
                                </div>
                                <div className='flex lg:justify-end lg:text-[22px] text-[12px] mt-[40px] text-[#000]'>
                                    <p>법인 전환 </p>
                                    <p className='pl-8'>벤처박스 Seed 투자</p>
                                </div>
                                <div className='lg:text-[48px] text-[22px]  flex justify-end mt-20 text-[#999] 0'> 2020-2021</div>
                                <div className='flex items-center '>
                                    <div className='w-[20px] h-[20px] rounded-full bg-[#5CB1FF] absolute left-[-10px] lg:block hidden '></div>
                                    <div className='border-b border-[#5CB1FF] w-full border-2 lg:mb-0 mb-[183px]'></div>
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>
            </div>
           

            
            </>
            )}
            
            
          
        </div>
        {isImageLoaded && (
        <Footer />
        )}
        </>
    );
};

export default InfoPage;